.order-form {
  position: relative;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 30px;
  background-color: #fafafa;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

h1 {
  color: #333;
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
}

.order-type {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
}

.order-type button {
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.order-type button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.order-type .active {
  background-color: #0056b3;
}

.client-name label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #444;
}

.client-name input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  margin-bottom: 20px;
  transition: border-color 0.3s;
}

.client-name input:focus {
  border-color: #007bff;
  outline: none;
}

.whatsapp-number label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.whatsapp-number input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  margin-bottom: 20px;
}

.camera {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.capture-button {
  margin-top: 15px;
  background-color: #28a745;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.capture-button:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.photo-preview {
  text-align: center;
  margin: 20px 0;
}

.photo-image {
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
  border: 4px solid #28a745;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.drink-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-bottom: 30px;
}

.drink-item {
  width: 180px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;
  padding: 15px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.drink-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.drink-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.drink-name {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

.drink-ingredients {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 15px;
}

.add-drink-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.add-drink-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.selected-drinks {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 12px;
  width: 320px;
  max-height: 500px;
  overflow-y: auto;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

.selected-drinks-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.selected-drink-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 10px 0;
  font-size: 1rem;
}

.selected-drink-info {
  display: flex;
  gap: 12px;
  align-items: center;
}

.selected-drink-name {
  font-weight: bold;
  color: #333;
}

.quantity-controls {
  display: flex;
  gap: 8px;
  align-items: center;
}

.quantity-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quantity-button:hover {
  background-color: #0056b3;
}

.remove-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-button:hover {
  background-color: #c82333;
}

.finalize-order-button {
  width: 100%;
  background-color: #28a745;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.finalize-order-button:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.finalize-order-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
