/* Estilo básico para o dashboard */
h2 {
    text-align: center;
  }
  
  section {
    margin: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  tbody tr:hover {
    background-color: #f1f1f1;
  }
  