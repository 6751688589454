/* Contêiner geral dos pedidos */
.order-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
}

/* Colunas de pedidos */
.order-column {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-column h2 {
  text-align: center;
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

/* Estilização do cartão do pedido */
.order-card {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.order-card:hover {
  transform: translateY(-5px);
  background-color: #f1f9ff;
}

.order-card.pronto {
  background-color: #d4edda; /* Fundo verde claro */
  border: 1px solid #155724; /* Borda verde escura */
}

.order-card.highlight {
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.03);
  }
}

/* Foto e nome ao lado da descrição */
.order-content {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;
}

/* Foto do pedido */
.order-photo img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
}

/* Nome do pedido */
.order-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  background-color: #eef3f7;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Detalhes do pedido */
.order-details {
  flex: 1;
}

.order-details strong {
  display: block;
  font-size: 1rem;
  color: #555;
  margin-bottom: 5px;
}

.order-details div {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #666;
}

/* Status do pedido */
.order-status {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 3px;
  font-weight: bold;
  font-size: 0.9rem;
  text-transform: capitalize;
}

.order-status.pendente {
  color: #856404;
  background-color: #fff3cd;
}

.order-status.pronto {
  color: #155724;
  background-color: #d4edda;
}

.order-status.cancelado {
  color: #721c24;
  background-color: #f8d7da;
}
